import React from 'react';
import ReactDOM from 'react-dom';

// Workaround for live reloading bug: https://github.com/facebook/create-react-app/issues/9904
if (module.hot) {
    module.hot.accept();
}

const App = () => {
    const buttonStyle = { backgroundColor: 'blue', color: 'white' };
    return (
        <div>
            <label class="label" for="name">Enter name:</label>
            <input id="name" type="text" />
            <button style={buttonStyle}>Submit</button>
        </div>
    );
};

ReactDOM.render(
    <App />,
    document.getElementById('root')
);